.headerPc {
    background-color: #F0F3F7;
    height: 50px;
    width: calc(100vw - 10px);
}

.navigationPc {
    gap: 30px;
    display: flex;
    justify-content: flex-end;
    margin-right: 100px;
    height: 100%;
    align-items: center;
}

.tabPc {
    cursor: pointer;
}

.fsTextContainerPc {
    padding-left: 100px;
    padding-top: 90px;
    color: #143354;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 35%;
}

.boldTitlePc {
    font-size: 3rem;
    font-weight: 600;
}

.regularTitlePc {
    font-size: 1.5rem;
}

.regularDescPc {
    font-size: 1.5rem;
}

.firstSlidePc {
    background-image: url(../../img/Home/www_graf_home.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 43.13vw;
    width: calc(100vw - 10px);
}

.secondSlidePc {
    margin-top: 50px;
    background: white;
    /* height: calc(100vh - 50px); */
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
}

.ssLeftSidePc {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ssRightSidePc {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.tsLeftSidePc {
    width: 50%;
}

.tsRightSidePc {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.thirdSlidePc {
    margin-top: 150px;
    background: white;
    /* height: calc(100vh - 50px); */
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
}

.footerPc {
    margin-top: 80px;
    height: 100px;
    background-color: #143354;
    color: white;
    display: flex;
    justify-content: space-between;
    padding-right: 50px;
}

.titlePc {
    color: #143354;
    font-size: 1.5rem;
    font-weight: 600;
    width: 100%;
}

.descPc {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding-top: 30px;
}

.photosPc {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.bookButton {
    background-color: #143354;
    color: white;
    width: 180px;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;
}

.titleAltPc {
    color: #143354;
    font-size: 1.5rem;
    font-weight: 600;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 50px);
}

.tsDescPc {
    background-color: #F0F3F7;
    /* height: calc(100% - 30px); */
    margin-top: 30px;
    border-radius: 20px;
    padding-left: 50px;
    gap: 30px;
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    word-break: break-word;
    padding-right: 140px;
    padding-bottom: 40px;
}

.tsDescTitle {
    color: #143354;
    font-size: 1.2rem;
    font-weight: 600;
}

.tsDescPar {
    display: flex;
    flex-direction: column;
    gap: 20px;
    line-height: 26px;
}

.footerLinks {
    padding-left: 100px;
    display: flex;
    align-items: center;
    height: 100%;
    gap: 50px;
    font-size: 0.8rem;
}

.linksLeft {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.linksRight {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.squareIcon {
    width: 0.7rem;
    height: 0.7rem;
}

.imgIcon {
    height: 100%;
}

.topbarCommissions {
    background-image: url(../../img/Commissions/commissions.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 20.8vw;
}

.commissionsMainText {
    display: flex;
    flex-direction: column;
    width: 560px;
    color: #143354;
    float: right;
    margin-right: 13%;
    height: 20.8vw;
    justify-content: center;
    gap: 10px;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.commissionsContainer {
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    margin-top: 105px;
    justify-content: space-evenly;
}

.leftPartCommissions {
    width: 42%;
    margin-right: 8%;
    gap: 20px;
    display: flex;
    flex-direction: column;
}

.rightPartCommissions {
    width: 42%;
}

.pricingBox {
    width: 11vw;
    height: 280px;
    border-radius: 20px;
    background: linear-gradient(to bottom, #F0F3F7 0%, #F0F3F7 75%, #143354 75%, #143354 25%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.unAvailableBox {
    position: absolute;
    height: 280px;
    width: 11vw;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #143354;
    font-size: 1.6rem;
    font-weight: 600;
}

#gallery {
    line-height:0;
    -webkit-column-count:5; /* split it into 5 columns */
    -webkit-column-gap:5px; /* give it a 5px gap between columns */
    -moz-column-count:5;
    -moz-column-gap:5px;
    column-count:4;
    column-gap:15px;
    margin:40px 100px 0px 100px;
 }
 #gallery img {
    width: 100% !important;
    height: auto !important;
    /* margin-bottom:15px; to match column gap */
 }

 @media (max-width: 1200px) {
    #gallery {
     -moz-column-count:    3;
     -webkit-column-count: 3;
     column-count:         3;
    }
 }

 #gallery img:hover {
    /* background: #143354;
    opacity: 0.7; */
    object-fit: cover;
    transition: 0.5s;
 }

 .photoContainer{
    position: relative;
    margin-bottom: 15px;
 }

 .photoTextContainer{
    position: absolute;
    color: white;
    width: 100%;
    height: 100%;
    visibility: hidden;

 }

 #gallery .photoContainer:hover .photoTextContainer{
    visibility: visible;
    background: #143354;
    opacity: 0.7;
    top:0px;
    left:0px;
    transition: 0.5s;
 }
 .photoText{
    position: absolute;
    left: 50%;
    top: 50%;
    text-align: center;
    transform: translateX(-50%) translateY(-50%);
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    gap:5px;
    font-size: 1.6rem;
    font-weight: 600;
    width: 100%;
 }
 .lowerText{
    font-weight: 300;
    font-size: 1.2rem;
 }

 .topbarGallery {
    background-image: url(../../img/Gallery/gallery_hero.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 20.8vw;
}

.topbarReviews {
    background-image: url(../../img/Gallery/reviews_hero.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 20.8vw;
}
#cardHolder{
    margin: 40px 100px 0px 100px;
    display: flex;
    flex-direction: row;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;
}
.card1 {
    min-width: 560px;
    height: 700px;
    background-color: #F0F3F7;
    border-radius: 20px;
    flex-basis: 30%;
}

.card2 {
    min-width: 1140px;
    height: 700px;
    background-color: #F0F3F7;
    border-radius: 20px;
    flex-basis: 60%;
}
.upperCard{
    display:flex;
    flex-direction: row;
    white-space: pre-wrap;
    justify-content: space-between;
    
    align-items: center;
}
.upperCard2{
    display:flex;
    white-space: pre-wrap;
    justify-content: space-between;
    
    align-items: center;
}
.cardHolder {
    margin: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;
}