.backgroundMobile{
    background-color: white;
}

.headerMobile {
    background-color: #F0F3F7;
    height: 50px;
    width: 100vw;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.headerTextMobile {
    color: #143354;
    font-weight: bold;
    font-size: 1rem;
    padding-right: 15px;
}

.parrotBackgroundMobile {
    background-image: url('../../img/Home/www_graf_home.jpg');
    background-color: #f0f3f7;
    background-size: cover;
    width: 100vw;
    height: 56.3vw;
    background-position-y: 20px;
    background-repeat: no-repeat;
}

.boldTitleMobile {
    color: #143354;
    font-weight: bolder;
    font-size: 1.4rem;
    line-height: 24px;
    word-break: break-word;
    max-width: 50%;
    padding-left: 4%;
    padding-top: 10px;
    min-width: 240px;
}

.slimTitleMobile {
    color: #143354;
    line-height: 24px;
    font-size:1rem;
    word-break: break-word;
    max-width: 50%;
    padding-left: 4%;
    min-width: 240px;
    margin-top: 5px;
}

.orderBackgroundMobile {
    margin-top: 20px;
    padding-top: 30px;
    padding-bottom:30px;
    padding-left: 4%;
    padding-right: 4%;
    word-break: break-word;
    display: flex;
    flex-direction: column;
    gap:10px;
    background-color: #F0F3F7;
    border-radius: 20px;
}

.bookButtonMobile {
    background: #143354;
    border-radius: 20px;
    height: 40px;
    width: 200px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    margin: auto;
}

.footerMobile {
    background-color: #143354;
    height: 150px;
    padding-left: 4%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: white;
    font-size: 0.8rem;
}

.iconBoxMobile {
}